import { TentBID } from "config-state/lib/config/Tent_B/tentB.types";
import type { State } from "config-state/src/config/Tent_B/tentB.config";
import type {
    CreateConfigurationDTO,
    ProductOverviewResult,
    ResultConfigurationOverviewDTO,
    ResultConfigurationSessionDTO,
    ResultSaveConfigurationDTO,
    UpsertConfigurationContextBody,
    UserDetailsResult,
} from "shared/lib/interfaces/api";
import type { Country } from "shared/lib/interfaces/country";
import type { BuildType, FormRequest } from "shared/lib/interfaces/formRequest";
import type { DatasheetInputData } from "shared/lib/interfaces/jobs/pdfjob.types";
import type { Order } from "shared/lib/interfaces/product";
import type {
    DataportClientGroup,
    DataportCountry,
    DataportCurrency,
    DataportLanguage,
    DataportLighting,
    DataportUsage,
} from "shared/lib/interfaces/sap/sap.types";
import type { LocMapper } from "shared/lib/localization/loc.types";
import { getFormattedDateTime } from "shared/lib/utils/timestamp.utils";

/*eslint-disable */
const apiURL = () => (window as any)["REDBUILD"]?.Options?.apiURL || "";

export async function login(email: string, password: string, type: "ad" | "local"): Promise<boolean> {
    const url = type === "ad" ? `${apiURL()}/api/v1/auth/login_ad/` : `${apiURL()}/api/v1/auth/login/`;
    email = email.trim();
    password = password.trim();

    return post<{
        access_token: string;
    }>(url, JSON.stringify({ email, password }))
        .then((data) => {
            if (data.access_token !== null) {
                localStorage.setItem("accessToken", data.access_token);
            }

            return true;
        })
        .catch(() => false);
}

export async function isLoggedIn(): Promise<boolean> {
    try {
        const response = await get<{
            access_token: string;
        }>(`/api/v1/auth/isloggedin`, "");
        localStorage.setItem("accessToken", response.access_token);

        return response.access_token !== undefined;
    } catch {
        return false;
    }
}

export async function userDetails() {
    try {
        return get<UserDetailsResult>("/api/v1/user/details");
    } catch {
        throw "Cannot retrieve User details.";
    }
}

export async function productOrder(
    id: string,
    hash_id: string | string,
    session_id: string | undefined,
    sales_country: string | undefined,
    future_price_data: string | undefined,
    buildType: BuildType | undefined
): Promise<Order> {
    const searchParams = new URLSearchParams();
    if (session_id !== undefined) {
        searchParams.append("planning", session_id);
    }
    if (sales_country !== undefined) {
        searchParams.append("sales_country", sales_country);
    }
    if (future_price_data !== undefined) {
        searchParams.append("future_price_data", future_price_data);
    }
    if (buildType !== undefined) {
        searchParams.append("snowLoad", buildType.snowLoad);
        searchParams.append("windLoad", buildType.windLoad);
        searchParams.append("pvSystem", buildType.pvSystem);
    }

    return get<Order>(
        `/api/v1/product/order/${id}/${hash_id}${searchParams.size > 0 ? "?" : ""}${searchParams.toString()}`
    );
}

export async function publishPricesConfig(
    id: string,
    hash_id: string,
    sales_country: string | undefined
): Promise<boolean> {
    const searchParams = new URLSearchParams();
    if (sales_country !== undefined) {
        searchParams.append("sales_country", sales_country);
    }

    return post<boolean>(
        `/api/v1/product/publishPricesConfig/${id}/${hash_id}${
            searchParams.size > 0 ? "?" : ""
        }${searchParams.toString()}`,
        ""
    );
}

export async function publishPricesAll(): Promise<boolean> {
    return post<boolean>(`/api/v1/product/publishPricesAll`, "");
}

export async function productTableExportConfig(
    id: string,
    hash_id: string,
    sales_country: string | undefined,
    priceOrigin: "sap_current" | "published"
) {
    const searchParams = new URLSearchParams();
    if (sales_country !== undefined) {
        searchParams.append("sales_country", sales_country);
    }
    searchParams.append("price_origin", priceOrigin);

    const response = await get<Blob>(
        `/api/v1/product/tableExportConfig/${id}/${hash_id}${
            searchParams.size > 0 ? "?" : ""
        }${searchParams.toString()}`,
        undefined,
        undefined,
        undefined,
        { responseType: "blob" }
    );
    const url = URL.createObjectURL(response);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${getFormattedDateTime()}-Baugruppen-Konfiguration-${hash_id}-Preis-${
        priceOrigin === "published" ? "veroeffentlicht" : "SAP-aktuell"
    }.xlsx`;
    a.click();
    URL.revokeObjectURL(url);

    return response;
}

export async function productTableExportAll(priceOrigin: "sap_current" | "published") {
    const searchParams = new URLSearchParams();
    searchParams.append("price_origin", priceOrigin);

    const response = await get<Blob>(
        `/api/v1/product/tableExportAll${searchParams.size > 0 ? "?" : ""}${searchParams.toString()}`,
        undefined,
        undefined,
        undefined,
        { responseType: "blob" }
    );
    const url = URL.createObjectURL(response);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${getFormattedDateTime()}-Baugruppen-Komplett-Preis-${
        priceOrigin === "published" ? "veroeffentlicht" : "SAP-aktuell"
    }.xlsx`;
    a.click();
    URL.revokeObjectURL(url);

    return response;
}

export function overview(sales_country: Country): Promise<ProductOverviewResult> {
    return get<ProductOverviewResult>(`/api/v1/product/overview/?sales_country=${sales_country}`);
}

export function getProductCountries(): Promise<Array<{ label: string; country: Country }>> {
    return get<Array<{ label: string; country: Country }>>(`/api/v1/product/countries`);
}

export function userPlannings(): Promise<ResultConfigurationOverviewDTO[]> {
    return get<ResultConfigurationOverviewDTO[]>("/api/v1/configuration/planning/mine/");
}

export function getConfigurationContext(hash_id: string): Promise<any> {
    return get(`/api/v1/configuration/context/${hash_id}`);
}

export function getConfigurationSession(session_id: string): Promise<ResultConfigurationSessionDTO> {
    return get(`/api/v1/configuration/planning/${session_id}`);
}

export function deleteConfigurationSession(session_id: string): Promise<any> {
    return post(`/api/v1/configuration/planning/delete/${session_id}`, null);
}

export function upsertConfigurationContext(hash_id: string, body: UpsertConfigurationContextBody): Promise<any> {
    return post(`/api/v1/configuration/upsert/${hash_id}`, JSON.stringify(body));
}

export function loadStateMock(): Promise<State> {
    return get(`/api/v1/product/state/`);
}

export function findConfiguration(hash_id: string): Promise<any> {
    return get<any>(`/api/v1/configuration/find/${hash_id}`);
}

export function saveConfiguration(data: CreateConfigurationDTO): Promise<ResultSaveConfigurationDTO> {
    return post<ResultSaveConfigurationDTO>(`/api/v1/configuration`, JSON.stringify(data));
}

export function sendSaveConfigurationMail(
    id: string,
    hash_id: string,
    mailAddress: string,
    gclid?: string | undefined
): Promise<boolean> {
    return post<boolean>(
        `/api/v1/configuration/send_save_configuration_mail/${id}/${hash_id}${gclid ? `?gclid=${gclid}` : ""}`,
        JSON.stringify({ to: mailAddress })
    );
}

export function sendRequestMail(
    id: string,
    hash_id: string,
    requestData: FormRequest,
    gclid?: string | undefined
): Promise<boolean> {
    return post<boolean>(
        `/api/v1/configuration/send_request_mail/${id}/${hash_id}${gclid ? `?gclid=${gclid}` : ""}`,
        JSON.stringify(requestData)
    );
}

export async function downloadDatasheet(
    id: string,
    hash_id: string,
    data: DatasheetInputData,
    loc: LocMapper
): Promise<any> {
    const datasheetResponse = await fetch(`/api/v1/product/datasheet/${id}/${hash_id}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
    });

    if (datasheetResponse.ok === false) {
        return false;
    }
    const blob = await datasheetResponse.blob();

    const localizedTentType = loc.map(`locKey_tent__${id}`).replaceAll(" ", "-");

    const downloadSlug = `${loc.map("locKey_datasheet")}-${localizedTentType}-${hash_id}`;
    const datasheetPage = new Blob([blob]);
    const anchor = document.createElement("a");
    anchor.download = `${downloadSlug}.pdf`;
    anchor.href = URL.createObjectURL(datasheetPage);
    anchor.click();
    URL.revokeObjectURL(anchor.href);

    // return post<any>(`/api/v1/product/datasheet/${id}/${hash_id}`, JSON.stringify(data));
}

export function getSapCountries() {
    return get<(DataportCountry | undefined)[]>(`/api/v1/dataport/countries`);
}

export function getSapCurrencies() {
    return get<(DataportCurrency | undefined)[]>(`/api/v1/dataport/currencies`);
}

export function getSapLanguages() {
    return get<(DataportLanguage | undefined)[]>(`/api/v1/dataport/languages`);
}

export function getSapClientGroups() {
    return get<(DataportClientGroup | undefined)[]>(`/api/v1/dataport/clientgroups`);
}

export function getSapUsage() {
    return get<(DataportUsage | undefined)[]>(`/api/v1/dataport/usage`);
}

export function getSapLighting(tentBID: TentBID) {
    return get<(DataportLighting | undefined)[]>(`/api/v1/dataport/lighting/${tentBID}`);
}

// ********************************************************************************* //
// ********************************************************************************* //
// ********************************************************************************* //
//use for new resources
export function post<T>(
    url: string,
    params: string,
    accessToken?: string,
    authMethod = "Bearer",
    responseType: XMLHttpRequestResponseType = ""
): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const http = new XMLHttpRequest();

        http.open("POST", url, true);

        if (responseType) {
            http.responseType = responseType;
        }
        //Send the proper header information along with the request
        http.setRequestHeader("Content-type", "application/json");
        http.setRequestHeader("Content-Language", localStorage.getItem("language") || "en");

        const cachedAccessToken = localStorage.getItem("accessToken");
        accessToken = cachedAccessToken !== null ? cachedAccessToken : accessToken;

        if (accessToken) {
            http.setRequestHeader("Authorization", `${authMethod} ${accessToken}`);
        }

        http.onreadystatechange = function () {
            //Call a function when the state changes.
            if (http.readyState === 4 && http.status >= 200 && http.status < 300) {
                if (responseType === "arraybuffer") {
                    resolve(http.response);
                } else {
                    const responsePlainOrJson = http
                        .getResponseHeader("content-type")
                        .toLowerCase()
                        .includes("application/json")
                        ? JSON.parse(http.response || http.responseText)
                        : http.response || http.responseText;

                    // TODO: enable localization again?
                    // if (http.getResponseHeader("Localization") === "true") {
                    //     LocalizationService.get().batchAdd(collectLocalization(responsePlainOrJson));
                    // }

                    resolve(responsePlainOrJson);
                }
            } else if (http.readyState === 4) {
                const responsePlainOrJson = http
                    .getResponseHeader("content-type")
                    .toLowerCase()
                    .includes("application/json")
                    ? JSON.parse(http.response || http.responseText)
                    : http.response || http.responseText;

                reject(responsePlainOrJson);
            }
        };

        http.onerror = function (err) {
            reject(err);
        };

        try {
            http.send(params);
        } catch (err) {
            console.error(err);
        }
    });
}

const getCache: { [cacheKey: string]: Promise<any> | undefined } = {};

//use for reading resources
export function get<T>(
    url: string,
    params: string = "",
    accessToken?: string,
    authMethod: string = "Bearer",
    options?: { withCredentials?: boolean; responseType?: XMLHttpRequestResponseType },
    useCache: boolean = false
): Promise<T> {
    const cacheKey = btoa(
        JSON.stringify({
            url,
            params,
            accessToken,
            authMethod,
            options,
        })
    );

    if (useCache === true && getCache[cacheKey] !== undefined) {
        return getCache[cacheKey] as Promise<T>;
    }

    const getPromise = new Promise<T>((resolve, reject) => {
        const http = new XMLHttpRequest();

        http.open("GET", url, true);

        //Send the proper header information along with the request
        http.setRequestHeader("Content-type", "application/json");
        http.setRequestHeader("Content-Language", localStorage.getItem("language") || "en");

        const cachedAccessToken = localStorage.getItem("accessToken");
        accessToken = cachedAccessToken !== null ? cachedAccessToken : accessToken;

        if (accessToken) {
            http.setRequestHeader("Authorization", `${authMethod} ${accessToken}`);
        }

        if (options) {
            for (const key in options) {
                http[key] = options[key];
            }
        }

        http.onreadystatechange = function () {
            //Call a function when the state changes.
            if (http.readyState === 4 && http.status >= 200 && http.status < 300) {
                const responsePlainOrJson = http
                    .getResponseHeader("content-type")
                    .toLowerCase()
                    .includes("application/json")
                    ? JSON.parse(http.response || http.responseText)
                    : http.response || http.responseText;

                // TODO: enable localization again?
                // if (http.getResponseHeader("Localization") === "true") {
                //     LocalizationService.get().batchAdd(collectLocalization(responsePlainOrJson));
                // }

                resolve(responsePlainOrJson);
            } else if (http.readyState === 4) {
                const responsePlainOrJson = http
                    .getResponseHeader("content-type")
                    .toLowerCase()
                    .includes("application/json")
                    ? JSON.parse(http.response || http.responseText)
                    : http.response || http.responseText;

                reject(responsePlainOrJson);
            }
        };

        http.onerror = function (err) {
            reject(err);
        };

        try {
            http.send(params);
        } catch (err) {
            console.error(err);
        }
    });

    getCache[cacheKey] = getPromise.catch(() => {
        delete getCache[cacheKey];
    });

    return getPromise;
}

//use to update resources
function put<T>(url: string, params: string, accessToken?: string, options?: { withCredentials: boolean }): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const http = new XMLHttpRequest();

        http.open("PUT", url, true);

        //Send the proper header information along with the request
        http.setRequestHeader("Content-type", "application/json");
        http.setRequestHeader("Content-Language", localStorage.getItem("language") || "en");

        const cachedAccessToken = localStorage.getItem("accessToken");
        accessToken = cachedAccessToken !== null ? cachedAccessToken : accessToken;

        if (accessToken) {
            http.setRequestHeader("Authorization", `Bearer ${accessToken}`);
        }

        if (options) {
            for (const key in options) {
                http[key] = options[key];
            }
        }

        http.onreadystatechange = function () {
            //Call a function when the state changes.
            if (http.readyState === 4 && http.status >= 200 && http.status < 300) {
                const responsePlainOrJson = http
                    .getResponseHeader("content-type")
                    .toLowerCase()
                    .includes("application/json")
                    ? JSON.parse(http.response || http.responseText)
                    : http.response || http.responseText;

                // TODO: enable localization again?
                // if (http.getResponseHeader("Localization") === "true") {
                //     LocalizationService.get().batchAdd(collectLocalization(responsePlainOrJson));
                // }

                resolve(responsePlainOrJson);
            } else if (http.readyState === 4) {
                reject(new Error("httpget: error at validation @ " + url));
            }
        };

        http.onerror = function (err) {
            reject(err);
        };

        try {
            http.send(params);
        } catch (err) {
            console.error(err);
        }
    });
}
