<script lang="ts">
    import { mutateSession } from "../../../store/session.store";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import InputAtom from "../../../atoms/InputAtom.svelte";
    import SelectableAtom from "../../../atoms/SelectableAtom.svelte";
    import type { SelectableAtomItem } from "../../../atoms/SelectableAtomInterface";
    import { IconLock, IconUserLight } from "../../../consts/icons";
    import { logoAlt, logoPath } from "../../../consts/reseller";
    import { addTrackingEvent } from "../../../services/tracking.service";
    import { loc } from "../../../store/loc.store";
    import { login } from "../../../store/user.store";
    import Modal from "../Modal.svelte";

    let username: string = "";
    let password: string = "";
    let loading = false;
    let loginOptions: SelectableAtomItem[] = [
        {
            id: "1",
            value: "ad",
            label: $loc.map("locKey_login_type_active_directory"),
            selected: true,
        },
        {
            id: "2",
            value: "local",
            label: $loc.map("locKey_login_type_local"),
            selected: false,
        },
    ];

    $: loginType = (loginOptions.find((saveOption) => saveOption.selected === true).value as "local" | "ad") || "ad";

    const onSubmit = (email: string, password: string, callback?: () => unknown) => {
        loading = true;
        login(email, password, loginType)
            .then((success) => {
                mutateSession({ isUserLoggedIn: success });
                callback?.()
            })
            .catch(() => addTrackingEvent("login error", "login error"))
            .finally(() => {
                loading = false;
            });
    };
</script>

<Modal backdrop={true} let:close {loading}>
    <svelte:fragment slot="header">
        <h2>{$loc.map("locKey_login")}</h2>
    </svelte:fragment>

    <img src={logoPath} alt={logoAlt} />

    <form on:submit|preventDefault={() => onSubmit(username, password, close)}>
        <InputAtom id="crm-id-input" bind:value={username} placeholder={$loc.map("locKey_modal_crm_id")}>
            <div slot="prefix">{@html IconUserLight}</div>
        </InputAtom>

        <InputAtom id="password-input" type="password" bind:value={password} placeholder={$loc.map("locKey_password")}>
            <div slot="prefix">{@html IconLock}</div>
        </InputAtom>

        <SelectableAtom
            items={loginOptions}
            let:item
            on:click={(e) => {
                loginOptions.forEach((option) => {
                    if (option.selected) {
                        option.selected = false;
                    }

                    if (option.id === e.detail.id) {
                        option.selected = true;
                    }
                });
                loginOptions = loginOptions;
            }}
        >
            {item.label}
        </SelectableAtom>

        <!-- hidden input to make form submit on enter work -->
        <input type="submit" style="display: none;" />
        <!---------------------------------------------------->
    </form>

    <svelte:fragment slot="footer" let:close>
        <ButtonAtom color="primary" size="small" on:click={() => onSubmit(username, password, close)}>
            {$loc.map("locKey_login")}
            <i class="zmdi zmdi-long-arrow-right" />
        </ButtonAtom>
    </svelte:fragment>
</Modal>

<style lang="scss" type="text/scss">
    @use "../../../scss/vars" as *;
    @use "../../../scss/rem" as *;

    img {
        height: rem(25);
        align-self: flex-start;
        margin: rem(5) 0;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: rem(10);
        margin-bottom: rem(15);
    }
</style>
